.white-icon svg {
  fill: white;
}

.link-color{
  color: white !important;
}

.link-color:hover{
  color: var(--primary-color) !important;
}