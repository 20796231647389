.breif-text {
  position: relative;
  font-weight:350;
  margin: 0px;
  background: none;
  line-height: 1.4em;
}

.main-btn {
  position: relative;
  line-height: 24px;
  color: white;
  font-size: 15px;
  font-weight: 700;
  background: #222222;
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 30px;
  border: 2px solid #222222;
  padding: 11px 30px 11px;
}
.main-btn:hover {
  background: none;
  color: #222222;
}
