.table-background {
  background-image: url("../images/quality/table-bg.jpg");
  width: 680px;
  background-size: cover;
  background-position: center;
  margin-top: 20px;
  margin-bottom: 30px;
  padding: 20px;
  border-radius: 15px;
}

.transparent-table {
  background-color: transparent !important;
  text-align: center;
}

.transparent-table th,
.transparent-table td {
  background-color: inherit !important;
}

.transparent-table th {
  color: #ef381b !important;
}
.transparent-table td {
  color: white !important;
}
